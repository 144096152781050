import Cookies from 'js-cookie';
import moment from 'moment';

export const terminateSession = () => {
  sessionStorage.removeItem('sessionId');
  sessionStorage.removeItem('merchantId');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('merchantData');
  sessionStorage.removeItem('merchantClientId');
  sessionStorage.removeItem('merchantName');
  sessionStorage.removeItem('timestamp');
  sessionStorage.removeItem('merchantCode');
  sessionStorage.removeItem('billerCount');
  sessionStorage.removeItem('privacyNoticeDisplay');
  sessionStorage.removeItem('redirectCheckout');
  sessionStorage.removeItem('persist:bayadExpressPersist');
};

export const removeBayadExpressPersist = () => {
  sessionStorage.removeItem('persist:bayadExpressPersist');
};

export const clearCookies = () => {
  Cookies.remove('merchantClientId');
  Cookies.remove('merchantName');
  Cookies.remove('merchantData');
  //Cookies.remove('transactionId'); //for cookie storage
  //Cookies.remove('sessionId'); //for cookie storage
};

export const createMerchantTimestamp = () => {
  return moment().add(15, 'minutes').unix();
};

export const storeRedirectCheckout = (redirectCheckout) => {
  sessionStorage.setItem('redirectCheckout', redirectCheckout);
};

export const getRedirectCheckout = () => {
  return sessionStorage.getItem('redirectCheckout');
};

export const storeSessionId = (sessionId) => {
  sessionStorage.setItem('sessionId', sessionId);
  //Cookies.set('sessionId', sessionId); //for cookie storage
};

export const getSessionId = () => {
  return sessionStorage.getItem('sessionId');
};

export const removeSession = () => {
  sessionStorage.removeItem('sessionId');
  //Cookies.remove('sessionId'); //for cookie storage
};

export const storeTimestamp = (timestamp) => {
  sessionStorage.setItem('timestamp', timestamp);
};

export const getBackToExpress = () => {
  return sessionStorage.getItem('backToExpress');
};

export const removeBackToExpress = () => {
  sessionStorage.removeItem('backToExpress');
};

export const setBackToExpress = (status) => {
  sessionStorage.setItem('backToExpress', status);
};

export const getTimestamp = () => {
  return sessionStorage.getItem('timestamp');
};

export const removeTimestamp = () => {
  sessionStorage.removeItem('timestamp');
};

export const setPrivacyNoticeDisplay = (privacyNoticeDisplay) => {
  sessionStorage.setItem('privacyNoticeDisplay', privacyNoticeDisplay);
};

export const isPrivacyNoticeDisplay = () => {
  return sessionStorage.getItem('privacyNoticeDisplay');
};

export const removePrivacyNoticeDisplay = () => {
  sessionStorage.removeItem('privacyNoticeDisplay');
};

export const storeTransactionId = (transactionId) => {
  sessionStorage.setItem('transactionId', transactionId);
  //Cookies.set('transactionId', transactionId); //for cookie storage
};

export const getTransactionId = () => {
  return sessionStorage.getItem('transactionId');
};

export const storeMerchantCode = (merchantCode) => {
  sessionStorage.setItem('merchantCode', merchantCode);
};

export const getMerchantCode = () => {
  return sessionStorage.getItem('merchantCode');
};

export const storeBillerCount = (billerCount) => {
  sessionStorage.setItem('billerCount', billerCount);
};

export const getBillerCount = () => {
  return sessionStorage.getItem('billerCount');
};

export const removeTransactionId = () => {
  sessionStorage.removeItem('transactionId');
  //Cookies.remove('transactionId'); //for cookie storage
};

export const storeMerchantName = (merchantName) => {
  sessionStorage.setItem('merchantName', merchantName);
  Cookies.set('merchantName', merchantName);
};

export const getMerchantName = () => {
  return sessionStorage.getItem('merchantName');
};

export const storeMerchantClientId = (merchantClientId) => {
  sessionStorage.setItem('merchantClientId', merchantClientId);
  Cookies.set('merchantClientId', merchantClientId);
};

export const getMerchantClientId = () => {
  return sessionStorage.getItem('merchantClientId');
};

export const storeMerchantId = (merchantId) => {
  sessionStorage.setItem('merchantId', merchantId);
};

export const getMerchantId = () => {
  return sessionStorage.getItem('merchantId');
};

export const storeToken = (token) => {
  sessionStorage.setItem('token', token);
};

export const getTokenData = () => {
  return sessionStorage.getItem('token');
};

export const removeToken = () => {
  sessionStorage.removeItem('token');
};

export const storeMerchantData = (merchantData) => {
  sessionStorage.setItem('merchantData', merchantData);
  Cookies.set('merchantData', merchantData);
};

export const getMerchantData = () => {
  return sessionStorage.getItem('merchantData');
};
