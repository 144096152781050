import InputField from './InputField';
import SelectField from './SelectField';
import DateField from './DateField';
import NumberField from './NumberField';
import DateYearRange from './DateYearRange';
import moment from 'moment';

//TYPE
export const TEXT = 'text';
export const NUMBER = 'number';
export const SELECT = 'select';
export const DATE_PICKER = 'date';
export const DATE_TIME_PICKER = 'datetime';
export const DATE_YEAR_RANGE = 'dateYearRange';
export const DATE_YEAR_RANGE_FORMAT = 'YYYY-YYYY';

//INVALID DATE
export const INVALID_DATE = 'INVALID DATE';
export const INVALID_DATE_MSG = 'Invalid Date Format';
export const INVALID_YEAR_MSG = 'Invalid Year Format';
export const YEAR_START_INVALID = 'The Year start must be less than to the value of Year end.';
export const YEAR_END_INVALID = 'The Year end must be greater than to the value of Year start.';

//DATE FORMAT
export const DATE = 'date';
export const DATE_TIME = 'datetime';

//FIELD
export const AMOUNT = 'amount';
export const CONVENIENCE_FEE = 'convenience_fee'; //convenience_fee and bank_fee is the same
export const BANK_FEE = 'bank_fee'; //convenience_fee and bank_fee is the same
export const SERVICE_FEE = 'service_fee';
export const ACCOUNT_NUMBER = 'account_number';
export const ACCOUNT_NAME = 'account_name';
export const CODE = 'code';

//FORM BUTTON NAME
export const PROCEED_TO_CHECKOUT = 'proceedToCheckout';
export const ADD_TO_CART = 'addToCart';

export const DEFAULT_MIN_VALUE = 'The minimum must be at least 1. Please enter a higher value.';
export const DEFAULT_MIN_AMOUNT_VALUE = 'The minimum amount for payments must be at least Php1.00. Please enter a higher amount.';

export const ALPHABET = 'a';
export const NUMERIC = 'n';

//BILLER CODE
export const MERALCO = 'MECOR'

const Controls = {
  InputField,
  SelectField,
  DateField,
  NumberField,
  DateYearRange,
};

export const initialFormValidation = (data) => {
  var fieldObject = {};

  data.forEach((field) => {
    if (Object.entries(field.rules).length === 0) {
      fieldObject[field.name] = true;
      if (field.type === DATE_PICKER && field.dateFormat === DATE_YEAR_RANGE_FORMAT) {
        fieldObject[field.name + '_year_start'] = true;
        fieldObject[field.name + '_year_end'] = true;
      }
    } else {
      if (field.type === DATE_PICKER && field.dateFormat === DATE_YEAR_RANGE_FORMAT) {
        fieldObject[field.name + '_year_start'] = false;
        fieldObject[field.name + '_year_end'] = false;
      }
      fieldObject[field.name] = false;
    }
  });
  return fieldObject;
};

export const initialFormData = (data) => {
  var fieldObject = {};

  data.forEach((field) => {
    fieldObject[field.name] = '';
    if (field.type === DATE_PICKER && field.dateFormat === DATE_YEAR_RANGE_FORMAT) {
      fieldObject[field.name + '_year_start'] = '';
      fieldObject[field.name + '_year_end'] = '';
    }
  });
  return fieldObject;
};

export const dateFormat = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const datetimeFormat = (date) => {
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
};

export const checkFormIsValid = (data) => {
  let isValid = true;
  let arr = [];
  Object.keys(data).forEach((prop) => {
    arr.push(data[prop]);
  });
  if (arr.includes(false)) {
    isValid = false;
  } else {
    isValid = true;
  }
  return isValid;
};

export const getTotalAmount = (subTotal, convenienceFee, billerFee) => {
  let totalAmount = 0;
  if (subTotal !== null && convenienceFee !== null && billerFee !== null) {
    totalAmount = parseFloat(subTotal) + parseFloat(convenienceFee) + parseFloat(billerFee);
  }
  return totalAmount;
};

export const getNumberTotal = (data, key) => {
  let total = 0;
  data.forEach((payment) => {
    total += parseFloat(payment[key]);
  });
  return total;
};

export const getConvenienceFeeTotal = (data, field) => {
  let convenienceFees = [];
  data.forEach((payment) => {
    if (convenienceFees.length > 0) {
      let checkIfExist = convenienceFees.findIndex((obj) => obj[ACCOUNT_NUMBER] === payment[ACCOUNT_NUMBER] && obj[CODE] === payment[CODE]);
      if (checkIfExist <= -1) {
        convenienceFees.push({ account_number: payment[ACCOUNT_NUMBER], code: payment[CODE], convenience_fee: payment[field] });
      }
    } else {
      convenienceFees.push({ account_number: payment[ACCOUNT_NUMBER], code: payment[CODE], convenience_fee: payment[field] });
    }
  });

  return getNumberTotal(convenienceFees, CONVENIENCE_FEE);
};

export const getBillerFeeTotal = (data) => {
  let billerFees = [];
  data.forEach((payment) => {
    if (billerFees.length > 0) {
      let checkIfExist = billerFees.findIndex((obj) => obj[ACCOUNT_NUMBER] === payment[ACCOUNT_NUMBER] && obj[CODE] === payment[CODE]);
      if (checkIfExist <= -1) {
        billerFees.push({ account_number: payment[ACCOUNT_NUMBER], code: payment[CODE], service_fee: payment[SERVICE_FEE] });
      }
    } else {
      billerFees.push({ account_number: payment[ACCOUNT_NUMBER], code: payment[CODE], service_fee: payment[SERVICE_FEE] });
    }
  });
  return getNumberTotal(billerFees, SERVICE_FEE);
};

export const getCommaSeparatedTwoDecimalsNumber = (number) => {
  const fixedNumber = Number.parseFloat(number).toFixed(2);
  const regex = /\B(?=(\d{3})+(?!\d))/g
  return String(fixedNumber).replace(regex, ',');
};

export const changeKeyColonToDotObj = (dataWithColon, objectContainer) => {
  let newProp = '';
  Object.keys(dataWithColon).forEach((prop) => {
    newProp = prop;
    if (prop.includes(':')) {
      objectContainer[newProp.replace(':', '.')] = dataWithColon[prop];
    } else {
      objectContainer[newProp] = dataWithColon[prop];
    }
  });
  return objectContainer;
};

export const deleteStartAndEndYearField = (fieldFormData) => {
  let newObject = {};
  Object.keys(fieldFormData).forEach((prop) => {
    if (!prop.includes('_year_start') && !prop.includes('_year_end')) {
      newObject[prop] = fieldFormData[prop];
    }
  });
  return newObject;
};

export const setFormDetails = (formData, objectContainer) => {
  Object.keys(formData).forEach((prop) => {
    objectContainer[prop] = formData[prop];
    // if (_.isObject(formData[prop])) {
    //   let fieldObj = formData[prop];
    //   Object.keys(fieldObj).forEach((fieldProp) => {
    //     objectContainer[prop + '.' + fieldProp] = fieldObj[fieldProp];
    //   });
    // } else {
    //   objectContainer[prop] = formData[prop];
    // }
  });
  return objectContainer;
};

export const emailValidation = (email) => {
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return !(!email || regex.test(email) === false);
};

export const alphabetValidation = (value) => {
  const regex = /^[a-zA-Z]+$/;
  return !(!value || regex.test(value) === false);
};

export const numberValidation = (value) => {
  const regex = /^[0-9]+$/;
  return !(!value || regex.test(value) === false);
};

export const alphabetDashValidation = (value) => {
  const regex = /^[a-zA-Z0-9-]+$/;
  return !(!value || regex.test(value) === false);
};

export const alphabetNumberSpaceValidation = (value) => {
  const regex = /^[a-zA-Z0-9 ]+$/;
  return !(!value || regex.test(value) === false);
};

export const alphabetSpaceValidation = (value) => {
  const regex = /^[a-zA-Z ]+$/;
  return !(!value || regex.test(value) === false);
};

export const alphabetNumberValidation = (value) => {
  const regex = /^[a-zA-Z0-9]+$/;
  return !(!value || regex.test(value) === false);
};

export const alphabetNumberPositionValidation = (start, end) => {
  let totalLength = parseInt(getNumbersOnly(start)) + parseInt(getNumbersOnly(end));

  let result = {
    startType: getLettersOnly(start),
    startLength: parseInt(getNumbersOnly(start)),
    startRange: [0, parseInt(getNumbersOnly(start))],
    endType: getLettersOnly(end),
    endLength: parseInt(getNumbersOnly(end)),
    endRange: [parseInt(getNumbersOnly(start)), parseInt(totalLength)],
    totalLength: totalLength,
  };
  return result;
};

export const getNumbersOnly = (value) => {
  let result = value.replace(/[^0-9]/g, '');
  return result;
};

export const getLettersOnly = (value) => {
  let result = value.replace(/[^a-zA-Z]/g, '');
  return result;
};

export const removeWhiteSpace = (value) => {
  let result = value ? value.replace(/\s/g, '') : '';
  return result;
};

export const dateFieldFormat = (format) => {
  let dateFormat = {};
  switch (format) {
    case 'YYYY':
      dateFormat = { views: ['year'], openTo: 'year', datePickerFormat: 'yyyy' };
      break;
    case 'MM/DD/YYYY':
    case 'MMDDYYYY':
      dateFormat = { views: ['date'], openTo: 'date', datePickerFormat: 'MM/dd/yyyy' };
      break;
    case 'YYYY/MM/DD':
      dateFormat = { views: ['year', 'month', 'date'], openTo: 'year', datePickerFormat: 'yyyy/MM/dd' };
      break;
    case 'YYYY,MM':
      dateFormat = { views: ['year', 'month'], openTo: 'year', datePickerFormat: 'yyyy,MM' };
      break;
    case 'YYYY-MM-DD':
      dateFormat = { views: ['year', 'month', 'date'], openTo: 'year', datePickerFormat: 'yyyy-MM-dd' };
      break;
    case 'YYYY/MM':
      dateFormat = { views: ['year', 'month'], openTo: 'year', datePickerFormat: 'yyyy/MM' };
      break;
    case 'MM/YYYY':
    case 'YYYYMM':
    case 'MMYYYY':
      dateFormat = { views: ['month', 'year'], openTo: 'month', datePickerFormat: 'yyyy/MM' };
      break;
    case 'MM':
      dateFormat = { views: ['month'], openTo: 'month', datePickerFormat: 'MMMM' };
      break;
    case 'YYYY-YYYY':
      dateFormat = { views: ['year'], openTo: 'year', datePickerFormat: 'yyyy' };
      break;
    default:
      dateFormat = { views: ['date'], openTo: 'date', datePickerFormat: 'MM/dd/yyyy' };
      break;
  }

  return dateFormat;
};

export const formattedDateValue = (date, format) => {
  let dateFormatted = {};
  switch (format) {
    case 'YYYY':
    case 'MM/DD/YYYY':
    case 'YYYY/MM/DD':
    case 'YYYY,MM':
    case 'YYYY-MM-DD':
    case 'YYYY/MM':
    case 'MM':
      dateFormatted = { dateValue: moment(date).format(format), dateDisplay: moment(date).format(format) };
      break;
    //case 'YYYY/MM':
    case 'MM/YYYY':
    case 'YYYYMM':
    case 'MMYYYY':
      dateFormatted = { dateValue: moment(date).format(format), dateDisplay: moment(date).format('YYYY/MM') };
      break;
    case 'MMDDYYYY':
      dateFormatted = { dateValue: moment(date).format(format), dateDisplay: moment(date).format('MM/DD/YYYY') };
      break;
    case 'YYYY-YYYY':
      dateFormatted = { dateValue: moment(date).format('YYYY'), dateDisplay: moment(date).format('YYYY') };
      break;
    default:
      dateFormatted = { dateValue: moment(date).format('MM/DD/YYYY'), dateDisplay: moment(date).format('MM/DD/YYYY') };
      break;
  }
  return dateFormatted;
};

export const dateFormatMatch = (format, value) => {
  let match = false;
  let regex = null;
  switch (format) {
    case 'YYYY':
      regex = /^\d{4}$/;
      break;
    case 'MM/DD/YYYY':
      regex = /^\d{2}\/\d{2}\/\d{4}$/;
      break;
    case 'YYYY/MM/DD':
      regex = /^\d{4}\/\d{2}\/\d{2}$/;
      break;
    case 'YYYY,MM':
      regex = /^\d{4}\,\d{2}$/;
      break;
    case 'YYYY-MM-DD':
      regex = /^\d{4}\-\d{2}\-\d{2}$/;
      break;
    case 'YYYY/MM':
      regex = /^\d{4}\/\d{2}$/;
      break;
    case 'MM':
      regex = /^\d{2}$/;
      break;
    case 'MM/YYYY':
      regex = /^\d{2}\/\d{4}$/;
      break;
    case 'YYYYMM':
      regex = /^\d{6}$/;
      break;
    case 'MMYYYY':
      regex = /^\d{6}$/;
      break;
    case 'MMDDYYYY':
      regex = /^\d{8}$/;
      break;
    case 'YYYY-YYYY':
      regex = /^\d{4}\-\d{4}$/;
      break;
    default:
      regex = /^\d{2}\/\d{2}\/\d{4}$/;
      break;
  }
  match = value.match(regex) ? true : false;
  return match;
};

export const removeEmptyString = (object) => {
  Object.entries(object).forEach(([key, value]) => {
    if (value && typeof value === 'object') removeEmptyString(value);
    if ((value && typeof value === 'object' && !Object.keys(value).length) || value === null || value === undefined || value.length === 0) {
      if (Array.isArray(object)) object.splice(key, 1);
      else delete object[key];
    }
  });
  return object;
};

export default Controls;
