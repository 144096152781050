import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { createTheme } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Constants from '../../utils/Constants';
import PaymentIcon from '@material-ui/icons/Payment';

const myStyles = makeStyles((theme) => ({
  headerLogo: {
    color: '#747474',
    fontSize: '110px',
  },
  dialogTitle: {
    textAlign: 'center',
    marginTop: 25,
  },
  titleText: {
    marginTop: 20,
    fontWeight: 600,
  },
  dialogContent: {
    textAlign: 'center',
  },
  dialogAction: {
    maxWidth: '50%',
    paddingTop: 25,
    paddingBottom: 25,
    margin: 'auto',
  },
  btnAction: {
    backgroundColor: '#E05A29 !important',
    color: 'white',
    letterSpacing: '0.5px',
  },
}));

const ModalConfirmation = (props) => {
  const {
    modalOpen = true,
    clickCloseOutside = false,
    modalButtonOkay,
    modalButtonCancel,
    modalTitle,
    modalContent,
    modalContent2,
    modalLogo,
    handleConfirm,
    maxWidth = 'sm',
  } = props;
  const classes = myStyles();
  const theme = createTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    if (clickCloseOutside) setOpen(false);
  };

  const handleConfirmYes = () => {
    handleConfirm(true);
    setOpen(false);
  };

  const handleConfirmNo = () => {
    handleConfirm(false);
    setOpen(false);
  };

  useEffect(() => {
    setOpen(modalOpen);
  }, [modalOpen]);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
        scroll={'body'}
        fullWidth={true}
        maxWidth={maxWidth}>
        <DialogTitle id='responsive-dialog-title' className={classes.dialogTitle}>
          {modalLogo ? <img className={classes.headerLogo} src={modalLogo} alt={'headerLogo'}></img> : <PaymentIcon className={classes.headerLogo} />}
          <br />
          <Typography variant='h5' component={'span'} className={classes.titleText}>
            {modalTitle ? modalTitle : ''}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            <Typography variant='subtitle1' component={'span'}>
              {modalContent ? modalContent : ''}
            </Typography>
          </DialogContentText>
          <DialogContentText>
            <Typography variant='subtitle1' component={'span'}>
              {modalContent2 ? modalContent2 : ''}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button variant='contained' fullWidth={true} className={classes.btnAction} onClick={handleConfirmNo}>
            {modalButtonCancel ? modalButtonCancel : Constants.confirmation_btn_cancel}
          </Button>
          <Button variant='contained' fullWidth={true} className={classes.btnAction} onClick={handleConfirmYes}>
            {modalButtonOkay ? modalButtonOkay : Constants.confirmation_btn_yes}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalConfirmation;
